import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import {
  AsyncSelector,
  Button,
  Label,
  Selector,
  TextInput,
  NumberInput,
} from "shared/components";
import {
  ageOptions,
  countryOptions,
  genderOptions,
  userRoleOptions,
} from "shared/constants";
import api from "shared/api";
import { refreshUserTeams } from "store/userTeams/actions";
import "./styles.scss";

const AddTeam = ({ history, userId, refreshUserTeamsAction }) => {
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState(userRoleOptions[0]);
  const [teamName, setTeamName] = useState("");
  const [teamCoach, setTeamCoach] = useState("");
  const [teamAbbr, setTeamAbbr] = useState("");
  const [teamGender, setTeamGender] = useState("");
  const [teamAge, setTeamAge] = useState("");
  const [teamInnings, setTeamInnings] = useState(undefined);
  const [teamLeague, setTeamLeague] = useState("");
  const [teamDivision, setTeamDivision] = useState("");
  const [teamCountry, setTeamCountry] = useState("");
  const [teamZip, setTeamZip] = useState("");
  const [teamCity, setTeamCity] = useState("");
  const [teamState, setTeamState] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      name: teamName,
      head_coach: teamCoach,
      abbreviation: teamAbbr,
      gender: get(teamGender, "value") || "",
      age_group: get(teamAge, "value") || "",
      innings_per_game: teamInnings,
      country: "usa", //get(teamCountry, "value") || "",
      city: teamCity,
      zipcode: teamZip,
      state: teamState,
      league: get(teamLeague, "id") || "",
      division: teamDivision,
      userteam: {
        role: userRole.value,
        user: userId,
      },
    };
    console.log(data);
    api
      .post("/api/v1/teams/", data)
      .then((res) => {
        const teamId = res.data.id;
        refreshUserTeamsAction(userId);
        history.push(`/${teamId}`);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    api.get("/api/v1/leagues/").then((res) => {
      setTeamLeague(res.data.results[0]);
    });
  }, []);

  return (
    <div className="AddTeam">
      <form className="AddTeam-form" onSubmit={handleSubmit}>
        <div className="form-container">
          <div className="form-column left">
            <div className="form-row">
              <Label inputId="user-role" text="Your role in team" />
              <Selector
                options={userRoleOptions}
                id="user-role"
                value={userRole.value}
                handleChange={(val) => {
                  const selected = userRoleOptions.find((o) => o.value === val);
                  setUserRole(selected);
                }}
              />
            </div>
            <div className="form-row">
              <Label inputId="team-name" text="Team name" />
              <TextInput
                id="team-name"
                value={teamName}
                handleChange={(val) => setTeamName(val)}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="team-coacg" text="Head coach" />
              <TextInput
                id="team-coach"
                value={teamCoach}
                handleChange={(val) => setTeamCoach(val)}
              />
            </div>
            <div className="form-row">
              <Label inputId="team-abbr" text="Team abbreviation" />
              <TextInput
                id="team-abbr"
                value={teamAbbr}
                handleChange={(val) => setTeamAbbr(val)}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="team-gender" text="Gender" />
              <Selector
                options={genderOptions}
                id="team-gender"
                value={teamGender.value}
                handleChange={(val) => {
                  const selected = genderOptions.find((o) => o.value === val);
                  setTeamGender(selected);
                }}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="team-age" text="Age group" />
              <Selector
                options={ageOptions}
                id="team-age"
                value={teamAge.value}
                handleChange={(val) => {
                  const selected = ageOptions.find((o) => o.value === val);
                  setTeamAge(selected);
                }}
                required
              />
              <div className="age-notice">
                {teamAge.value === "12_under" &&
                  "Your team has children ages 12 and under. Only Confirmed Members of your team will see players' details."}
              </div>
            </div>
            {/* <div className="form-row">
              <Label inputId="team-innings" text="Innings per game" />
              <NumberInput
                min="1"
                max="20"
                id="team-innings"
                value={teamInnings}
                handleChange={(val) => setTeamInnings(val)}
              />
            </div> */}
          </div>
          {/* <div className="form-column right"> */}
          {/* <div className="form-row">
              <Label inputId="team-league" text="League name" />
              <AsyncSelector
                valueKey="id"
                labelKey="name"
                fetchOptions={() => {}}
                searchable={false}
                fetchInitialOptions={() => api.get("/api/v1/leagues/")}
                handleChange={(val) => {
                  setTeamLeague(val);
                }}
                value={get(teamLeague, "name") || ""}
                required
              />
            </div>
            <div className="form-row">
              <Label inputId="team-division" text="Division (optional)" />
              <TextInput
                id="team-division"
                value={teamDivision}
                handleChange={(val) => setTeamDivision(val)}
              />
            </div> */}
          {/* <div className="form-row">
              <Label inputId="team-country" text="Country" />
              <Selector
                options={countryOptions}
                id="team-country"
                value={teamCountry.value}
                handleChange={(val) => {
                  const selected = countryOptions.find((o) => o.value === val);
                  setTeamCountry(selected);
                }}
              />
            </div>
            <div className="form-row">
              <Label inputId="team-zip" text="Zip code" />
              <TextInput
                id="team-zip"
                value={teamZip}
                handleChange={(val) => setTeamZip(val)}
                placeholder="eq. 11201"
              />
            </div>
            <div className="form-row">
              <Label inputId="team-city" text="City" />
              <TextInput
                id="team-city"
                value={teamCity}
                handleChange={(val) => setTeamCity(val)}
              />
            </div>
            <div className="form-row">
              <Label inputId="team-state" text="State" />
              <TextInput
                id="team-state"
                value={teamState}
                handleChange={(val) => setTeamState(val)}
              />
            </div>
          </div> */}
        </div>
        <div className="form-submit">
          <Button theme="success" type="submit" disabled={loading} size="lg">
            Create a team
          </Button>
        </div>
      </form>
    </div>
  );
};

AddTeam.propTypes = {
  history: PropTypes.object.isRequired,
  refreshUserTeamsAction: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  userId: get(state, "user.userID"),
});

const mapDispatchToProps = {
  refreshUserTeamsAction: refreshUserTeams,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddTeam));
