import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import api from "shared/api";
import { Table } from "shared/components";
import { displayValue } from "shared/helpers";
// import './styles.scss';

const TeamStatsPitching = ({ history, teamId, seasonId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSeasonStats = (team, season) => {
    api
      .get(
        `/api/v1/playerstats/?limit=1000&team__id=${team}&season__id=${season}`
      ) //?team__id=${team}&season__id=${season}
      .then((res) => {
        console.log(res);
        const stats = get(res, "data") || [];
        setLoading(false);
        setData(stats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSeasonStats(teamId, seasonId);
  }, [teamId, seasonId]);

  const redirectToPlayerPage = (rowData) => {
    const playerId = get(rowData, "original.id");
    history.push(`/${teamId}/players/${playerId}`);
  };

  return (
    <div className="TeamStatsBasic">
      <Table
        data={data.results}
        loading={loading}
        columns={[
          {
            accessor: "player_data",
            Header: "Name",
            Cell: (cellData) => {
              return displayValue(
                cellData.value.first_name + " " + cellData.value.last_name
              );
            },
            minWidth: 100,
          },
          {
            accessor: "ip",
            Header: "IP",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "gp",
            Header: "GP",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "gs",
            Header: "GS",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "w",
            Header: "W",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "l",
            Header: "L",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "sv",
            Header: "SV",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "so",
            Header: "SO",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "era",
            Header: "ERA",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "whip",
            Header: "WHIP",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "pik",
            Header: "PIK",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "baa",
            Header: "BAA",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "bb",
            Header: "BB",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
        ]}
        minRows={1}
        showPagination={data?.results?.length > 100 ? true : false}
        // clickable
        // handleClick={(rowData) => redirectToPlayerPage(rowData)}
        // style={{
        //   height: `${get(data, 'players.length', 0) > 10 ? '450px' : 'unset'}`, // This will force the table body to overflow and scroll, since there is not enough room
        // }}
      />
    </div>
  );
};

TeamStatsPitching.propTypes = {
  history: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  seasonId: get(state, "activeTeam.activeSeason.id"),
});

export default connect(mapStateToProps)(withRouter(TeamStatsPitching));
