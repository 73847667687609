import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import { SearchInput, Table } from "shared/components";
import { displayValue, sortSeasons } from "shared/helpers";
import { searchTeams } from "./actions";
import "./styles.scss";

const SearchTeam = ({ history }) => {
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [query, setQuery] = useState("");

  const initSearchTeams = () => {
    searchTeams(query)
      .then((res) => {
        if (isMounted.current) {
          setTeams(get(res, "data.results"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    initSearchTeams();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const redirectToTeamPage = (rowData) => {
    const teamId = get(rowData, "original.id");
    history.push(`/${teamId}`);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    initSearchTeams();
  };

  return (
    <div className="SearchTeam">
      <div className="search-input-container">
        <SearchInput
          id="SearchTeam-input"
          placeholder="Search for a team by name"
          handleChange={(val) => setQuery(val)}
          handleClearBtn={() => setQuery("")}
          onSearch={handleSearchSubmit}
          value={query}
          disabled={loading}
        />
      </div>
      <div className="results-container">
        <Table
          data={teams}
          noDataText=""
          loading={loading}
          loadingText="Searching for a team"
          columns={[
            {
              accessor: "name",
              Header: "Name",
              minWidth: 150,
            },

            // {
            //   accessor: "league.name",
            //   Header: "League",
            //   Cell: (cellData) => displayValue(cellData.value),
            // },
            {
              accessor: "league.seasons",
              Header: "Season",
              Cell: (cellData) => {
                if (!cellData.value) {
                  return "-";
                }
                const seasons = sortSeasons(cellData.value);
                const current = seasons.find((s) => s.is_current);
                return `${get(current, "year") || "-"}`;
              },
              minWidth: 150,
            },
            {
              accessor: "head_coach",
              Header: "Head coach",
              Cell: (cellData) => displayValue(cellData.value),
              minWidth: 150,
            },
            {
              accessor: "gender",
              Header: "Gender",
              Cell: (cellData) => displayValue(cellData.value),
              minWidth: 150,
            },
          ]}
          clickable
          handleClick={(rowData) => redirectToTeamPage(rowData)}
          minRows={1}
          showPagination={teams.length > 10}
        />
      </div>
    </div>
  );
};

SearchTeam.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchTeam);
