import React, { useState, useEffect } from "react";
import get from "lodash.get";
import {
  // Button,
  Table,
  // TextInput,
  SearchInput,
  PlayerPopup,
} from "shared/components";
import { displayValue, formatDate } from "shared/helpers";
import { defaultDateFormat } from "shared/constants";
import { searchPlayers } from "./actions";
import "./styles.scss";

const SearchPlayers = () => {
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  // const [firstNameQuery, setFirstNameQuery] = useState('');
  // const [lastNameQuery, setLastNameQuery] = useState('');
  const [query, setQuery] = useState("");

  const [playerDisplay, setPlayerDisplay] = useState(null);

  const initSearchPlayers = () => {
    searchPlayers(query)
      .then((res) => {
        if (isMounted.current) {
          setPlayers(get(res, "data.results"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    initSearchPlayers();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const showPlayerPopup = (rowData) => {
    const playerData = get(rowData, "original");
    setPlayerDisplay(playerData);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    initSearchPlayers();
  };

  return (
    <div className="SearchPlayers">
      {/* <form className="search-input-container" onSubmit={handleSearchSubmit}>
        <TextInput
          handleChange={val => setFirstNameQuery(val)}
          value={firstNameQuery}
          placeholder="First name"
        />
        <TextInput
          handleChange={val => setLastNameQuery(val)}
          value={lastNameQuery}
          placeholder="Last name"
        />
        <Button
          type="submit"
          disabled={loading}
        >
          Search
        </Button>
      </form> */}
      <div className="search-input-container">
        <SearchInput
          id="SearchPlayers-input"
          placeholder="Search for a player by first or last name"
          handleChange={(val) => setQuery(val)}
          handleClearBtn={() => setQuery("")}
          onSearch={handleSearchSubmit}
          value={query}
          disabled={loading}
        />
      </div>
      <div className="results-container">
        <Table
          data={players}
          noDataText=""
          loading={loading}
          loadingText="Searching players"
          columns={[
            {
              accessor: "first_name",
              Header: "First name",
              minWidth: 150,
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "last_name",
              Header: "Last name",
              minWidth: 150,
              Cell: (cellData) => displayValue(cellData.value),
            },
            {
              accessor: "date_of_birth",
              Header: "Birthday",
              minWidth: 150,
              Cell: (cellData) => formatDate(cellData.value, defaultDateFormat),
            },
            {
              accessor: "position",
              Header: "Position",
              Cell: (cellData) => displayValue(cellData.value),
              minWidth: 150,
            },
          ]}
          clickable
          handleClick={(rowData) => showPlayerPopup(rowData)}
          minRows={1}
          showPagination={players.length > 10}
        />
      </div>
      {playerDisplay && (
        <PlayerPopup
          isOpen={!!playerDisplay}
          player={playerDisplay}
          close={() => setPlayerDisplay(null)}
        />
      )}
    </div>
  );
};

export default SearchPlayers;
