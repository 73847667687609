/* eslint-disable */
import React, { useEffect, useState } from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import { NavLink, withRouter, Switch, Route } from "react-router-dom";
import { ContentLoader, NotFound, PageHeader, Notice } from "shared/components";
import PlayerProfile from "../PlayerProfile";
import PlayerStats from "../PlayerStats";
import { getPlayer } from "./actions";
import "./styles.scss";

const PlayerContainer = ({ activeSeason, match }) => {
  const teamId = get(match, "params.teamId");
  const playerId = get(match, "params.playerId");
  // handle getting season from query param - set season id with it
  // and add activeseason to redux - maybe even on team fetch when param accesible
  const seasonId = get(activeSeason, "id");
  const isMounted = React.useRef(true);

  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);

  const fetchPlayerData = () => {
    getPlayer(playerId, seasonId)
      .then((res) => {
        const playerData = get(res, "data.results[0]") || {};
        console.log("Result: ", res);
        if (isMounted.current) {
          setPlayer(playerData);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPlayerData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="PlayerContainer">
        <PageHeader />
        <ContentLoader text="Getting player data" />
      </div>
    );
  }

  if (!player) {
    return <NotFound />;
  }

  return (
    <div className="PlayerContainer">
      <PageHeader />
      <div className="PlayerContainer-breadcrumb">
        <NavLink to={`/${teamId}/players`}>
          <span>&#8249;</span>
          back to players list
        </NavLink>
      </div>
      <div className="PlayerContainer-menu">
        <NavLink exact to={`/${teamId}/players/${playerId}`}>
          Profile
        </NavLink>
        <NavLink to={`/${teamId}/players/${playerId}/stats/batting`}>
          Batting stats
        </NavLink>
        <NavLink to={`/${teamId}/players/${playerId}/stats/pitching`}>
          Pitching stats
        </NavLink>
      </div>
      <div className="PlayerContainer-notices">
        {get(player, "player.first_name") === "Matija" && (
          <Notice title="Rule alert" theme="error" size="sm">
            Player achieved daily max of pitches in a game!
          </Notice>
        )}
      </div>
      <Switch>
        <Route
          exact
          path="/:teamId/players/:playerId"
          render={() => (
            <PlayerProfile
              player={player}
              fetchPlayerData={fetchPlayerData}
              teamId={Number(teamId)}
            />
          )}
        />
        <Route
          path="/:teamId/players/:playerId/stats"
          render={() => <PlayerStats player={player} teamId={Number(teamId)} />}
        />
        <Route render={() => <NotFound />} />
      </Switch>
    </div>
  );
};

PlayerContainer.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(PlayerContainer);
