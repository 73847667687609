import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import { NavLink, Route, Switch } from "react-router-dom";
import { refreshUserTeams } from "store/userTeams/actions";
import { refreshActiveTeam } from "store/activeTeam/actions";
import { Button, NotFound, Notification, PageHeader } from "shared/components";
import { TeamCommunity, TeamHome, TeamStats } from "../pages";
import { checkUserTeam } from "./helpers";
import TeamImport from "../pages/TeamImport";
import { followTeam } from "./actions";
import "./styles.scss";

const TeamDashboard = ({
  activeSeason,
  refreshActiveTeamAction,
  refreshUserTeamsAction,
  team,
  userId,
  userTeams,
}) => {
  const teamId = get(team, "id");
  const leagueId = get(team, "league.id");
  const userTeam = checkUserTeam(userTeams, teamId);
  const role = get(userTeam, "role") || null;

  const [loading, setLoading] = useState(false);

  const handleTeamFollowClick = () => {
    setLoading(true);

    followTeam(teamId, userId)
      .then(() => refreshUserTeamsAction(userId))
      .then(() => {
        setLoading(false);
        Notification("success", "Team followed successfuly");
      })
      .catch(() => {
        setLoading(false);
        Notification(
          "error",
          "Error occured",
          "There was an error while trying to follow this team"
        );
      });
  };

  return (
    <div className="TeamDashboard">
      <PageHeader />
      <div className="TeamDashboard-status">
        {userTeam ? null : (
          <div className="TeamDashboard-actions">
            <div className="team-actions">
              <Button
                onClick={handleTeamFollowClick}
                disabled={loading}
                size="sm"
              >
                follow team
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="TeamDashboard-menu">
        <NavLink exact to={`/${teamId}`}>
          Home
        </NavLink>
        <NavLink to={`/${teamId}/stats/batting`}>Batting stats</NavLink>
        <NavLink to={`/${teamId}/stats/pitching`}>Pitching stats</NavLink>

        <NavLink exact to={`/${teamId}/community`}>
          Community
        </NavLink>
        <NavLink to={`/${teamId}/imports`}>Imports</NavLink>
      </div>
      <Switch>
        <Route exact path="/:teamId" render={() => <TeamHome team={team} />} />
        <Route path="/:teamId/stats" render={() => <TeamStats team={team} />} />
        <Route
          exact
          path="/:teamId/community"
          render={() => (
            <TeamCommunity
              team={team}
              role={role}
              refreshTeam={refreshActiveTeamAction}
              userId={userId}
            />
          )}
        />
        <Route
          exact
          path="/:teamId/imports"
          render={() => (
            <TeamImport
              activeSeason={activeSeason}
              teamId={Number(teamId)}
              leagueId={leagueId}
            />
          )}
        />
        <Route render={() => <NotFound />} />
      </Switch>
    </div>
  );
};

TeamDashboard.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  refreshUserTeamsAction: PropTypes.func.isRequired,
  refreshActiveTeamAction: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  userTeams: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  activeSeason: get(state, "activeTeam.activeSeason"),
  userTeams: get(state, "userTeams.details"),
  userId: get(state, "user.userID"),
});

const mapDispatchToProps = {
  refreshUserTeamsAction: refreshUserTeams,
  refreshActiveTeamAction: refreshActiveTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDashboard);
