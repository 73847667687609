/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { NavLink, withRouter } from "react-router-dom";
import { Dropdown, IconPlus } from "shared/components";
import "./styles.scss";

class TeamsNavigation extends Component {
  constructor() {
    super();

    this.state = {
      isMenuDisplayed: false,
    };

    this.popupRef = React.createRef();
  }

  handleDropdown = () => {
    const { isMenuDisplayed } = this.state;
    this.setState({
      isMenuDisplayed: !isMenuDisplayed,
    });
  };

  render() {
    const { isMenuDisplayed } = this.state;
    const { userTeams } = this.props;

    return (
      <div className="TeamsNavigation">
        <div className="profile-label">
          <div
            className="sidebar-item"
            onClick={this.handleDropdown}
            role="presentation"
          >
            Your teams
            <span className="arrow-down">{`${"▾"}`}</span>
          </div>
        </div>
        {isMenuDisplayed && (
          <Dropdown ref={this.popupRef} close={this.handleDropdown}>
            {
              <div className="TeamsNavigation-menu">
                <>
                  {userTeams.map((t) => (
                    <NavLink
                      key={get(t, "team.id")}
                      to={`/${get(t, "team.id")}`}
                      onClick={this.handleDropdown}
                    >
                      {`${get(t, "team.name")} - ${get(t, "role")}`}
                    </NavLink>
                  ))}
                </>
                <NavLink className="create-team" exact to="/?st=3">
                  <div
                    className="create-team-inner"
                    onClick={() => this.handleDropdown()}
                    role="button"
                  >
                    <IconPlus
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#717374"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    Create new team
                  </div>
                </NavLink>
              </div>
            }
          </Dropdown>
        )}
      </div>
    );
  }
}

TeamsNavigation.propTypes = {
  userTeams: PropTypes.array,
};

TeamsNavigation.defaultProps = {
  userTeams: [],
};

export default withRouter(TeamsNavigation);
