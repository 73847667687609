import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import api from "shared/api";
import { Table } from "shared/components";
import { displayValue } from "shared/helpers";
// import './styles.scss';

const TeamStatsBatting = ({ history, teamId, seasonId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSeasonStats = (team, season) => {
    api
      .get(
        `/api/v1/playerstats/?limit=1000&team__id=${team}&season__id=${season}`
      ) //?team__id=${team}&season__id=${season}
      .then((res) => {
        console.log(res);
        const stats = get(res, "data") || [];
        setLoading(false);
        setData(stats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSeasonStats(teamId, seasonId);
  }, [teamId, seasonId]);

  const redirectToPlayerPage = (rowData) => {
    const playerId = get(rowData, "original.id");
    history.push(`/${teamId}/players/${playerId}`);
  };

  return (
    <div className="TeamStatsBasic">
      <Table
        data={data.results}
        loading={loading}
        columns={[
          {
            accessor: "player_data",
            Header: "Name",
            Cell: (cellData) => {
              return displayValue(
                cellData.value.first_name + " " + cellData.value.last_name
              );
            },
            minWidth: 100,
          },
          {
            accessor: "ba",
            Header: "AVG",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "obp",
            Header: "OBP",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "ops",
            Header: "OPS",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "slg",
            Header: "SLG",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "gp",
            Header: "GP",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "pa",
            Header: "PA",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "ab",
            Header: "AB",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "bb",
            Header: "BB",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "hbp",
            Header: "HBP",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "so",
            Header: "SO",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "so",
            Header: "SO",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "h",
            Header: "H",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "1b",
            Header: "1B",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "2b",
            Header: "2B",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "3b",
            Header: "3B",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "hr",
            Header: "HR",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
          {
            accessor: "rbi",
            Header: "RBI",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },

          {
            accessor: "r",
            Header: "R",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },

          {
            accessor: "sb",
            Header: "SB",
            Cell: (cellData) => displayValue(cellData.value),
            minWidth: 50,
          },
        ]}
        minRows={1}
        showPagination={data?.results?.length > 100 ? true : false}
        // clickable
        // handleClick={(rowData) => redirectToPlayerPage(rowData)}
        // style={{
        //   height: `${get(data, 'players.length', 0) > 10 ? '450px' : 'unset'}`, // This will force the table body to overflow and scroll, since there is not enough room
        // }}
      />
    </div>
  );
};

TeamStatsBatting.propTypes = {
  history: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  seasonId: get(state, "activeTeam.activeSeason.id"),
});

export default connect(mapStateToProps)(withRouter(TeamStatsBatting));
