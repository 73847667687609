/* eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import ProfileNavigation from "./components/ProfileNavigation";
import TeamsNavigation from "./components/TeamsNavigation";
import LeaguesNavigation from "./components/LeaguesNavigation";
import logo from "./logo.png";
import "./styles.scss";

const Header = ({ user, userTeams, location }) => {
  const userLeagues = get(user, "leagues_admin") || [];
  const isUserStaff = get(user, "is_staff");

  const isNCS = get(user, "organizations").some((element, i) => {
    if (element.name === "NCS" && element.is_admin == true) return true;
    if (i === get(user, "organizations").length) return false;
  });

  const isAdmin = get(user, "organizations")[0]?.is_admin;

  console.log(isNCS);
  const isLeagueContext = get(location, "pathname").includes("/leagues");
  const isLeaderboard = get(location, "pathname").includes("/leaderboard");
  const isEvents =
    get(location, "pathname").includes("/events") &&
    !get(location, "pathname").includes("/leaderboard");

  const isHomepage = !(isLeagueContext || isLeaderboard || isEvents);

  console.log("FE deployment is working!");
  return (
    <header className="Header">
      <div className="Header-primary">
        <div className="logo">
          <NavLink to="/">
            <img src={logo} alt="designated hitter logo" height="40px" />
          </NavLink>
        </div>
        {/* {(userLeagues.length || isUserStaff) && (
          <div className="context-menu">
            <NavLink to="/" className={`${isHomepage ? "activeRoute" : ""}`}>
              Teams
            </NavLink>
            <NavLink
              to="/leagues"
              className={`${isLeagueContext ? "activeRoute" : ""}`}
            >
              Leagues
            </NavLink>
            <NavLink
              to="/events"
              className={`${isEvents ? "activeRoute" : ""}`}
            >
              Events
            </NavLink>
          </div>
        )} */}
        {isAdmin && (
          <div className="context-menu">
            <NavLink to="/" className={`${isHomepage ? "activeRoute" : ""}`}>
              Teams
            </NavLink>
            {/* <NavLink
              to="/leagues"
              className={`${isLeagueContext ? "activeRoute" : ""}`}
            >
              Leagues
            </NavLink>
            <NavLink
              to="/events"
              className={`${isEvents ? "activeRoute" : ""}`}
            >
              Events
            </NavLink>
            <NavLink
              to="/leaderboard"
              className={`${isLeaderboard ? "activeRoute" : ""}`}
            >
              Leaderboard
            </NavLink> */}
          </div>
        )}
      </div>
      <div className="Header-secondary">
        {/* <div className="feedback-link">
          <a
            className="Button Button-size-sm Button-theme-success"
            href="https://dh-testing.canny.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Leave feedback
          </a>
        </div> */}
        {isLeagueContext && (
          <div className="leaguesNavigation">
            <LeaguesNavigation userLeagues={userLeagues} />
          </div>
        )}
        <div className="teamsNavigation">
          <TeamsNavigation userTeams={userTeams} />
        </div>
        <div className="profileNavigation">
          <ProfileNavigation user={user} />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  userTeams: PropTypes.array,
  location: PropTypes.object.isRequired,
};

Header.defaultProps = {
  user: null,
  userTeams: [],
};

const mapStateToProps = (state) => ({
  user: get(state, "user.details"),
  userTeams: get(state, "userTeams.details"),
});

export default connect(mapStateToProps)(withRouter(Header));
