import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import {
  Button,
  IconDelete,
  PageHeader,
  Table,
  Notification,
} from "shared/components";
import {
  displayValue,
  formatDate,
  isAdmin,
  isEditableSeason,
} from "shared/helpers";
import { gamesStatusOptions } from "shared/constants";
import { mapGameListItems } from "./helpers";
import AddGameForm from "./components/AddGameForm";
import DeleteGamePopup from "./components/DeleteGamePopup";
import "./styles.scss";

const GamesList = ({ activeSeason, history, games, team }) => {
  const teamId = get(team, "id");
  const isAuth = isAdmin(teamId);

  const [isAddGameModalDisplayed, setAddGameModalDisplay] = useState(false);

  const [gameToDelete, setGameToDelete] = useState(null);
  const [isDeleteGameModalDisplayed, setDeleteGameModalDisplay] =
    useState(false);

  const data = mapGameListItems(games, teamId);

  const redirectToGamePage = (rowData) => {
    const gameId = get(rowData, "original.id");
    history.push(`/${teamId}/games/${gameId}`);
  };

  const handleGameAdd = () => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can add games to the team"
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification("error", "Action not available for past seasons");
    }

    return setAddGameModalDisplay(true);
  };

  const handleGameDelete = (index) => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can delete games from the team"
      );
    }

    if (!isEditableSeason(activeSeason.id)) {
      return Notification("error", "Action not available for past seasons");
    }

    const selected = games[index];

    setGameToDelete(selected);
    return setDeleteGameModalDisplay(true);
  };

  return (
    <div className="GamesList">
      <PageHeader />
      <div className="PlayersList-actions">
        <Button size="sm" onClick={handleGameAdd}>
          Add game
        </Button>
      </div>
      <Table
        data={data}
        noDataText=""
        columns={[
          {
            accessor: "time_start",
            Header: "Date",
            Cell: (cellData) => formatDate(cellData.value),
            minWidth: 150,
          },
          {
            accessor: "opponent",
            Header: "Opponent",
            Cell: (cellData) => {
              const isAway = get(cellData, "original.away");
              const opponent = get(cellData, "value") || "TBD";
              return isAway ? `@ ${opponent}` : opponent;
            },
            minWidth: 150,
          },
          {
            accessor: "status",
            Header: "Status",
            Cell: (cellData) => {
              const status = cellData.value || "";

              if (!status) {
                return "-";
              }

              const statusObject = gamesStatusOptions.find(
                (i) => i.value === status
              );

              return displayValue(statusObject.label, "-");
            },
            minWidth: 150,
          },
          {
            Header: "Delete",
            headerClassName: "text-center",
            className: "text-center button-wrapper",
            sortable: false,
            Cell: (cellData) => (
              <button
                aria-label="Delete game"
                className="delete-button"
                onClick={(e) => {
                  e.stopPropagation();

                  if (cellData.original.status !== "scheduled") {
                    Notification(
                      "error",
                      "Game event cannot be deleted",
                      "You can delete only scheduled game events"
                    );
                    return;
                  }
                  handleGameDelete(cellData.index);
                }}
                type="button"
              >
                <IconDelete height="20px" width="20px" color="#ee5253" />
              </button>
            ),
            minWidth: 150,
          },
        ]}
        clickable
        handleClick={(rowData) => redirectToGamePage(rowData)}
        minRows={1}
        showPagination={false}
        defaultSorted={[
          {
            id: "time_start",
            desc: true,
          },
        ]}
      />
      {isAddGameModalDisplayed && (
        <AddGameForm
          isOpen={isAddGameModalDisplayed}
          close={() => setAddGameModalDisplay(false)}
          team={team}
          activeSeason={activeSeason}
        />
      )}
      {isDeleteGameModalDisplayed && (
        <DeleteGamePopup
          isOpen={isDeleteGameModalDisplayed}
          close={() => setDeleteGameModalDisplay(false)}
          team={team}
          activeSeason={activeSeason}
          game={gameToDelete}
        />
      )}
    </div>
  );
};

GamesList.propTypes = {
  activeSeason: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  games: PropTypes.array.isRequired,
};

export default withRouter(GamesList);
