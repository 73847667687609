import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import { connect } from "react-redux";
import {
  // DescriptionList,
  NotAuthorized,
  // Tab,
  // Tabs,
  // TabContent,
  // TabsHeader,
  Table,
} from "shared/components";
import { displayValue, isAdmin } from "shared/helpers";
import api from "shared/api";
// import {
//   mockBattingAway,
//   mockBattingHome,
//   mockPitchingAway,
//   mockPitchingHome,
// } from './mockData';
import "./styles.scss";
import TeamStatsPitching from "./components/TeamStatsPitching";
import TeamStatsBatting from "./components/TeamStatsBatting";

const GameBoxScore = ({ game, history, teamId, seasonId }) => {
  const isAuth = isAdmin(teamId);
  const gameId = get(game, "id");
  const homeTeamId = get(game, "team_home.id");
  const awayTeamId = get(game, "team_away.id");

  console.log(game);

  if (!isAuth) {
    return <NotAuthorized message="Only team admins can see game stats" />;
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchSeasonStats = () => {
    api
      .get(`/api/v1/games/${gameId}/stats`)
      .then((res) => {
        const stats = get(res, "data") || [];
        setLoading(false);
        setData(stats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSeasonStats();
  }, [gameId]);
  const [teamPick, setTeamPick] = useState(1);
  const [statsPick, setStatsPick] = useState(2);

  return (
    <div className="GameBoxScore">
      <div className="TeamHolder">
        <div
          className={`team-1 ${teamPick == 1 && "active"}`}
          onClick={() => {
            setTeamPick(1);
          }}
        >
          {game.team_home.name}
        </div>
        <div className="vs"> VS </div>

        <div
          className={`team-2 ${teamPick == 2 && "active"}`}
          onClick={() => {
            setTeamPick(2);
          }}
        >
          {game.team_away.name}
        </div>
      </div>

      <div className="stats-holder">
        <div
          className={`stats-item ${statsPick == 2 && "active"}`}
          onClick={() => setStatsPick(2)}
        >
          Batting stats
        </div>
        <div
          className={`stats-item ${statsPick == 1 && "active"}`}
          onClick={() => setStatsPick(1)}
        >
          Pitching stats
        </div>
      </div>
      <div className="tables-container">
        {statsPick == 1 && (
          <TeamStatsPitching
            teamId={teamPick == 1 ? homeTeamId : awayTeamId}
            gameId={gameId}
            seasonId={seasonId}
          />
        )}
        {statsPick == 2 && (
          <TeamStatsBatting
            teamId={teamPick == 1 ? homeTeamId : awayTeamId}
            gameId={gameId}
            seasonId={seasonId}
          />
        )}
      </div>
    </div>
  );
};

GameBoxScore.propTypes = {
  game: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  seasonId: get(state, "activeTeam.activeSeason.id"),
  userTeams: get(state, "userTeams.details"),
  userId: get(state, "user.userID"),
});

export default connect(mapStateToProps)(GameBoxScore);
