/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import { Table } from "shared/components";
import { displayValue, sortSeasons } from "shared/helpers";
import WelcomeScreen from "../WelcomeScreen";
import "./styles.scss";
import { connect } from "react-redux";

const UserTeams = ({ history, openSelectedTab, userTeams, user }) => {
  const redirectToTeamPage = (rowData) => {
    const teamId = get(rowData, "original.team.id");
    history.push(`/${teamId}/`);
  };
  console.log("User: ", user);
  if (!userTeams || !userTeams.length) {
    return <WelcomeScreen openSelectedTab={openSelectedTab} />;
  }

  return (
    <div className="UserTeams">
      <div className="user-team-table-container">
        <Table
          data={userTeams}
          columns={[
            {
              accessor: "team.name",
              Header: "Name",
              Cell: (cellData) => displayValue(cellData.value),
              minWidth: 150,
            },

            // {
            //   accessor: "team.league.name",
            //   Header: "League",
            //   Cell: (cellData) => displayValue(cellData.value),
            // },
            {
              accessor: "team.league.seasons",
              Header: "Season",
              Cell: (cellData) => {
                if (!cellData.value) {
                  return "-";
                }
                const seasons = sortSeasons(cellData.value);
                const current = seasons.find((s) => s.is_current);
                return `${get(current, "year") || "-"}`;
              },
              minWidth: 150,
            },
            {
              accessor: "role",
              Header: "Role",
              Cell: (cellData) => displayValue(cellData.value),
              minWidth: 150,
            },
            {
              accessor: "team.gender",
              Header: "Gender",
              Cell: (cellData) => displayValue(cellData.value),
              minWidth: 150,
            },
          ]}
          clickable
          handleClick={(rowData) => redirectToTeamPage(rowData)}
          minRows={1}
          showPagination={false}
        />
      </div>
    </div>
  );
};

UserTeams.propTypes = {
  history: PropTypes.object.isRequired,
  openSelectedTab: PropTypes.func.isRequired,
  userTeams: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: get(state, "user.details"),
});

export default connect(mapStateToProps)(withRouter(UserTeams));
