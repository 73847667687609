/* eslint-disable */
import axios from "axios";
import getCookie from "./helpers/getCookie";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "x-csrftoken";
// axios.defaults.headers.common.Authorization = "Token " + getCookie("Token");
axios.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 403) {
      window.location.href = "/login"; // eslint-disable-line no-undef
    }
    return response;
  },
  (error) => Promise.reject(error)
);

//
// const endpoint = "https://app-dev.designatedhitter.app";
const endpoint = "";
// const endpoint = "http://127.0.0.1:8000";

const getPath = (url) => `${endpoint}${url}`;

const api = {
  get: (url, config = undefined) => axios.get(getPath(url), config),
  post: (url, data = undefined, config = undefined) =>
    axios.post(getPath(url), data, config),
  patch: (url, data, config) => axios.patch(getPath(url), data, config),
  delete: (url, config = undefined) => axios.delete(getPath(url), config),
};

axios.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params["organization"] = getCookie("Organization");
  return config;
});

export default api;
