/* eslint-disable */
import React, { useEffect, useState } from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SetCookie from "shared/helpers/setCookie";
import { withRouter } from "react-router-dom";
import {
  Button,
  Label,
  Notification,
  PasswordInput,
  TextInput,
} from "shared/components";
import { login, loginError, loginSuccess } from "store/user/actions";
import "./styles.scss";
import logo from "./logo.png";
const LoginContainer = ({
  history,
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  isCheckingUser,
  user,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (get(user, "id")) {
      history.push("/");
    }
  });

  const handleLogin = (e) => {
    e.preventDefault();

    if (isCheckingUser) {
      return;
    }

    setLoading(true);

    const loginData = {
      email,
      password,
    };

    loginAction(loginData)
      .then((res) => {
        loginSuccessAction(get(res, "data"));
        SetCookie("Token", res.data.key, 14);
        SetCookie("Organization", res.data.user.organizations[0]?.id, 15);

        history.push("/");
      })
      .catch((err) => {
        setLoading(false);
        Notification(
          "error",
          "Unable to login",
          "You provided wrong credentials"
        );
        loginErrorAction(err);
      });
  };

  return (
    <div className="LoginContainer">
      <div className="form-container">
        <form
          className="login-form"
          id="login-form"
          onSubmit={(e) => handleLogin(e)}
        >
          <fieldset>
            <div className="logo-cont">
              <img src={logo} alt="designatted hitter logo" height="80" />
            </div>
            <div className="form-row">
              <Label inputId="login-email" text="Email" />
              <TextInput
                id="login-email"
                type="email"
                placeholder="Type your email here"
                handleChange={(val) => setEmail(val)}
                value={email}
                required
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <Label inputId="login-password" text="Password" />
              <PasswordInput
                id="login-password"
                placeholder="Type your email here"
                handleChange={(val) => setPassword(val)}
                value={password}
                required
                disabled={loading}
              />
            </div>
            <div className="form-row">
              <a className="login-forgot" href="/account/password/reset/">
                Forgot password?
              </a>
            </div>
            <div className="form-row btn-cont">
              <Button size="lg" type="submit" disabled={isCheckingUser}>
                Login
              </Button>
            </div>
            {/* <div className="form-row row-register">
              <p>
                If you don’t have an account, register
                <button
                  className="register-redirect"
                  type="button"
                  onClick={() => history.push("/register")}
                >
                  here
                </button>
              </p>
            </div> */}
          </fieldset>
        </form>
      </div>
    </div>
  );
};

LoginContainer.propTypes = {
  isCheckingUser: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  loginAction: PropTypes.func.isRequired,
  loginErrorAction: PropTypes.func.isRequired,
  loginSuccessAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isCheckingUser: get(state, "user.checkingUser"),
  user: get(state, "user.details"),
});

const mapDispatchToProps = {
  loginAction: login,
  loginErrorAction: loginError,
  loginSuccessAction: loginSuccess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
