import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { NavLink } from "react-router-dom";
import "./styles.scss";
import version from "version";

const Sidebar = ({ location, teamId }) => {
  const handleActive = () => {
    const isTeamRoot = get(location, "pathname") === `/${teamId}`;
    const isTeamStats = get(location, "pathname").includes(`/${teamId}/stats`);
    const isTeamCommunity = get(location, "pathname").includes(
      `/${teamId}/community`
    );

    return isTeamRoot || isTeamStats || isTeamCommunity;
  };

  return (
    <aside className="Sidebar Sidebar-mobile">
      <div className="Sidebar-inner">
        <NavLink
          exact
          to={`/${teamId}`}
          className={`Sidebar-item team ${handleActive() ? "active" : ""}`}
        >
          <div className="icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.5"
              height="20"
              viewBox="0 0 27 25"
            >
              <g id="Team_Icon" transform="translate(-4480 320.402)">
                <circle
                  id="Ellipse_504"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(4480 -320.402)"
                  fill="#fff"
                />
                <circle
                  id="Ellipse_505"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(4480 -314.934)"
                  fill="#fff"
                />
                <circle
                  id="Ellipse_506"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(4480 -309.467)"
                  fill="#fff"
                />
                <circle
                  id="Ellipse_507"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(4480 -304)"
                  fill="#fff"
                />
                <line
                  id="Line_522"
                  x2="17.029"
                  transform="translate(4487.971 -318.902)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_523"
                  x2="17.029"
                  transform="translate(4487.971 -313.53)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_524"
                  x2="17.029"
                  transform="translate(4487.971 -307.871)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_525"
                  x2="17.029"
                  transform="translate(4487.971 -302.5)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
            <p>Team</p>
          </div>
          <span className="top" />
          <span className="bottom" />
        </NavLink>
        <NavLink to={`/${teamId}/players`} className="Sidebar-item players">
          <div className="icon-container">
            <svg
              viewBox="0 0 64 80"
              height="25"
              width="25"
              fill="#fff"
              stroke="#181F1C"
            >
              <path d="M33,27v34h-2V27c0-0.289-0.195-0.625-0.413-0.815c-5.156-4.512-9.239-9.947-12.136-16.155l-2.185-4.682l4.06-1.923  l0.453,1.811c1.515,6.06,5.266,11.221,10.562,14.531l0.128,0.08C31.632,19.949,31.815,20,32,20s0.368-0.051,0.53-0.152l0.128-0.08  c5.297-3.311,9.048-8.471,10.562-14.531l0.453-1.811l4.06,1.923l-2.185,4.682c-2.896,6.208-6.979,11.643-12.208,16.218  C33.124,26.438,33,26.712,33,27z M22.281,3h19.439l-0.438,1.751c-1.351,5.4-4.638,10.021-9.281,13.063  c-4.644-3.041-7.931-7.663-9.281-13.063L22.281,3z M5,37v-2h8v2H5z M13,20v13H3V19.86c0-4.998,2.918-9.609,7.435-11.749l4.024-1.906  l2.18,4.671C19.591,17.202,23.726,22.757,29,27.451V61H15V20H13z M59,37h-8v-2h8V37z M61,33H51V20h-2v41H35V27.452  c5.239-4.648,9.397-10.224,12.361-16.576l2.18-4.671l4.024,1.906C58.082,10.25,61,14.862,61,19.86V33z" />
            </svg>
            <p>Players</p>
          </div>
          <span className="top" />
          <span className="bottom" />
        </NavLink>
        <NavLink to={`/${teamId}/games`} className="Sidebar-item games">
          <div className="icon-container">
            <svg viewBox="0 0 12.7 15.875" width="25" height="25">
              <g transform="translate(0,-284.29998)">
                <path
                  d="m 2.2892,285.2533 1.1718,1.1731 -1.6082,1.6064 -1.1718,-1.1731 a 1.1798799,1.1798799 0 0 1 1.6082,-1.6064 z"
                  fill="#fff"
                  stroke="none"
                />
                <path
                  d="m 1.5582,294.9335 a 0.28078121,0.28078121 0 0 0 -0.3956,0.3985 l 0.7878,0.7869 a 0.28078139,0.28078139 0 0 0 0.3981,-0.396 l 3.3537,-3.259 0.6477,-0.5392 0.6478,0.5392 3.3537,3.259 a 0.28078121,0.28078121 0 0 0 0.3981,0.396 l 0.7877,-0.7869 a 0.28078121,0.28078121 0 0 0 -0.3956,-0.3985 l -3.2553,-3.3573 -0.4569,-0.5501 2.5376,-2.1123 0.6828,-0.6835 -1.6082,-1.6064 -0.6828,0.6835 -2.0089,2.4189 -2.0089,-2.4189 -0.6827,-0.6835 -1.6082,1.6064 0.6827,0.6835 2.5376,2.1123 -0.4569,0.5501 z"
                  fill="#fff"
                  stroke="none"
                />
                <path
                  d="m 10.4107,285.2533 -1.1719,1.1731 1.6083,1.6064 1.1718,-1.1731 a 1.1798799,1.1798799 0 0 0 -1.6082,-1.6064 z"
                  fill="#fff"
                  stroke="none"
                />
              </g>
            </svg>
            <p>Games</p>
          </div>
          <span className="top" />
          <span className="bottom" />
        </NavLink>
        {/* <NavLink to={`/${teamId}/league`} className="Sidebar-item league">
          <div className="icon-container">
            <svg viewBox="0 0 32 40" height="25px" width="25px" fill="#fff">
              <g>
                <path
                  d="M25,25H22V22a1,1,0,0,0-1-1H19l0-2.68a7,7,0,0,0,2.52-2,3.51,3.51,0,0,0,3,1.7,1,1,0,0,0,0-2A1.5,1.5,0,0,1,23,14.5c0-.58.95-1.54,1.79-2.39C26.22,10.67,28,8.87,28,6.5A3.5,3.5,0,0,0,24.5,3a3.45,3.45,0,0,0-1.5.35V3a1,1,0,0,0-1-1H10A1,1,0,0,0,9,3v.35A3.45,3.45,0,0,0,7.5,3,3.5,3.5,0,0,0,4,6.5c0,2.37,1.78,4.17,3.21,5.61C8.05,13,9,13.92,9,14.5A1.5,1.5,0,0,1,7.5,16a1,1,0,0,0,0,2,3.51,3.51,0,0,0,3-1.7,7,7,0,0,0,2.5,2L13,21H11a1,1,0,0,0-1,1v3H7a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V26A1,1,0,0,0,25,25ZM24.5,5A1.5,1.5,0,0,1,26,6.5c0,1.54-1.34,2.9-2.63,4.21-.13.12-.25.24-.37.37V6.5A1.5,1.5,0,0,1,24.5,5ZM8.63,10.71C7.34,9.4,6,8,6,6.5a1.5,1.5,0,0,1,3,0v4.58C8.88,11,8.76,10.83,8.63,10.71Z"
                />
              </g>
            </svg>
            <p>League</p>
          </div>
          <span className="top" />
          <span className="bottom" />
        </NavLink> */}
      </div>
      <div className="version-cont__sidebar">{version}</div>
    </aside>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  teamId: PropTypes.string,
};

Sidebar.defaultProps = {
  location: {},
  teamId: "",
};

export default Sidebar;
