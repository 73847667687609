/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "shared/components";
import { FileUploader } from "shared/components";
import "./styles.scss";
import FallbackImage from "../../../../../../shared/components/icons/stockphoto.jpeg";

function EditLogoForm({
  close,
  isOpen,
  uploading,
  progress,
  handleSubmitClick,
  handleWrongFileType,
  handleItemRemove,
  validFileTypes,
  team,
  setPreview,
  preview,
}) {
  const [dirty, setDirty] = useState(false);

  const handleClose = () => {
    if (
      !dirty ||
      window.confirm("Are you sure you want to discard your changes?")
    ) {
      close();
    }
  };
  function Preview(res) {
    setDirty(true);
    setPreview(res);
  }

  return (
    <Modal isOpen={isOpen} closeCb={handleClose} title="Edit Logo" size="lg">
      <div className="Logo">
        <img
          id="profileUp"
          src={
            preview ? preview.previewUrl : team.logo ? team.logo : FallbackImage
          }
          alt="profile"
        />
      </div>
      <FileUploader
        disabled={uploading}
        progress={progress}
        onSubmit={handleSubmitClick}
        onWrongFileTypeReject={handleWrongFileType}
        onItemRemove={handleItemRemove}
        validFileTypes={validFileTypes}
        onPreview={Preview}
      />
    </Modal>
  );
}

export default EditLogoForm;
