import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
import {
  Button,
  ContentLoader,
  DescriptionList,
  IconDelete,
  Table,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Notification,
  NotAuthorized,
  UnderConstruction,
  TextInput,
  Selector,
} from "shared/components";
import { displayValue, isAdmin } from "shared/helpers";
import api from "shared/api";
import AddUserForm from "./components/AddUserForm";
import AddMultipleUserForm from "./components/AddMultipleUserForm";
import UserRole from "./components/UserRole";
import UserPlayer from "./components/UserPlayer";
import DeleteUserPopup from "./components/DeleteUserPopup";
import "./styles.scss";

const TeamCommunity = ({ role, team, userId, currentUserId, seasonId }) => {
  const teamId = get(team, "id");
  const isAuth = isAdmin(teamId);
  const isMounted = React.useRef(true);

  const [loading, setLoading] = useState(true);
  const [isAddUserDisplayed, setAddUserDisplay] = useState(false);
  const [isMultipleAddUserDisplayed, setMultipleAddUserDisplay] =
    useState(false);
  const [isDeleteUserDisplayed, setDeleteUserDisplay] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [users, setUsers] = useState([]);

  const fetchUsers = () => {
    api
      .get(`/api/v1/teams/${teamId}/users/?season=${seasonId}`)
      .then((res) => {
        if (isMounted.current) {
          const data = get(res, "data.users") || [];
          setUsers(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchUsers();
    // add to redux maybe
  }, [seasonId]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const verifiedUsers = users.filter((u) => get(u, "email_verified"));
  const notVerifiedUsers = users.filter((u) => !get(u, "email_verified"));

  const handleUsersAdd = () => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can add users to the team"
      );
    }

    return setAddUserDisplay(true);
  };

  const handleMultipleUsersAdd = () => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can add users to the team"
      );
    }

    return setMultipleAddUserDisplay(true);
  };

  const handleUserDelete = (user) => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can add users to the team"
      );
    }
    setUserToDelete(user);
    return setDeleteUserDisplay(true);
  };

  if (loading) {
    return <ContentLoader text="Getting team users" />;
  }

  return (
    <div className="TeamCommunity">
      <div className="TeamCommunity-user">
        {role && (
          <DescriptionList
            data={[{ label: "Your role", value: displayValue(role) }]}
          />
        )}
      </div>
      <div className="TeamCommunity-list">
        <div className="TeamCommunity-list-actions">
          <Button size="sm" onClick={handleUsersAdd}>
            Add team member
          </Button>
          <Button size="sm" onClick={handleMultipleUsersAdd} theme="info">
            Add multiple team members
          </Button>
        </div>
        <div className="TeamCommunity-tabs">
          <Tabs>
            <TabsHeader>
              <Tab>Team users</Tab>
              <Tab>Pending invitations</Tab>
              {/* <Tab>Requests</Tab> */}
            </TabsHeader>
            <TabContent>
              <Table
                data={verifiedUsers}
                noDataText=""
                filterable
                NoDataComponent={() => null}
                defaultFilterMethod={(filter, row) => {
                  const rowValue = row[filter.id] || "";
                  if (!rowValue) {
                    return false;
                  }
                  return String(row[filter.id].toLowerCase()).includes(
                    filter.value.toLowerCase()
                  );
                }}
                columns={[
                  {
                    accessor: "email",
                    Header: "Email",
                    Cell: (cellData) => displayValue(cellData.value),
                    Filter: (cellInfo) => {
                      const { filter, onChange } = cellInfo;

                      return (
                        <TextInput
                          placeholder="Search users by email"
                          handleChange={(e) => onChange(e.target.value)}
                          value={filter ? filter.value : ""}
                        />
                      );
                    },
                  },
                  {
                    accessor: "first_name",
                    Header: "First name",
                    Cell: (cellData) => displayValue(cellData.value),
                    Filter: (cellInfo) => {
                      const { filter, onChange } = cellInfo;

                      return (
                        <TextInput
                          placeholder="Search users by first name"
                          handleChange={(e) => onChange(e.target.value)}
                          value={filter ? filter.value : ""}
                        />
                      );
                    },
                  },
                  {
                    accessor: "last_name",
                    Header: "Last name",
                    Cell: (cellData) => displayValue(cellData.value),
                    Filter: (cellInfo) => {
                      const { filter, onChange } = cellInfo;

                      return (
                        <TextInput
                          placeholder="Search users by last name"
                          handleChange={(e) => onChange(e.target.value)}
                          value={filter ? filter.value : ""}
                        />
                      );
                    },
                  },
                  {
                    accessor: "userteam",
                    Header: "Role",
                    Cell: (cellData) => {
                      const id = get(cellData, "original.id");
                      const email = get(cellData, "original.email");
                      const userteam = get(cellData, "value") || [];
                      if (!get(userteam, "id")) {
                        return "-";
                      }

                      return (
                        <UserRole
                          currentUserId={userId}
                          fetchUsers={fetchUsers}
                          role={userteam}
                          teamId={teamId}
                          userRoleId={id}
                          userRoleEmail={email}
                          isAuth={isAuth}
                        />
                      );
                    },
                    // eslint-disable-next-line consistent-return
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      const rowValue = row[filter.id].role || "";
                      if (!rowValue) {
                        return false;
                      }

                      // if (filter.value === 'admin') {
                      //   return row[filter.id].role === 'admin' ||
                      //     row[filter.id].role === 'coach' ||
                      //     row[filter.id].role === 'scorekeeper';
                      // }
                      // if (filter.value === 'fan') {
                      //   return row[filter.id].role === 'fan';
                      // }
                      if (filter.value === rowValue) {
                        return true;
                      }
                      return false;
                    },
                    Filter: (cellInfo) => {
                      const { filter, onChange } = cellInfo;
                      return (
                        <Selector
                          options={[
                            { value: "all", label: "Show all" },
                            { value: "admin", label: "Admin" },
                            { value: "coach", label: "Coach" },
                            { value: "scorekeeper", label: "Scorekeeper" },
                            {
                              value: "assistant_coach",
                              label: "Assistant coach",
                            },
                            {
                              value: "team_volunteer",
                              label: "Team volunteer",
                            },
                            { value: "fan", label: "Fan" },
                          ]}
                          value={filter ? filter.value : "all"}
                          handleChange={(val) => onChange(val)}
                        />
                      );
                    },
                  },
                  {
                    accessor: "userplayers",
                    Header: "Family of",
                    Cell: (cellData) => {
                      const players = get(cellData, "value") || [];
                      const email = get(cellData, "original.email");
                      if (!players.length) {
                        return "-";
                      }

                      return (
                        <UserPlayer
                          fetchUsers={fetchUsers}
                          players={players}
                          isAuth={isAuth}
                          userRoleEmail={email}
                        />
                      );
                    },
                    // eslint-disable-next-line consistent-return
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      const rowValue = row[filter.id].role || "";
                      if (!rowValue) {
                        return false;
                      }

                      // if (filter.value === 'admin') {
                      //   return row[filter.id].role === 'admin' ||
                      //     row[filter.id].role === 'coach' ||
                      //     row[filter.id].role === 'scorekeeper';
                      // }
                      // if (filter.value === 'fan') {
                      //   return row[filter.id].role === 'fan';
                      // }
                      if (filter.value === rowValue) {
                        return true;
                      }
                      return false;
                    },
                    Filter: (cellInfo) => {
                      const { filter, onChange } = cellInfo;
                      return (
                        <Selector
                          options={[
                            { value: "all", label: "Show all" },
                            { value: "admin", label: "Admin" },
                            { value: "coach", label: "Coach" },
                            { value: "scorekeeper", label: "Scorekeeper" },
                            {
                              value: "assistant_coach",
                              label: "Assistant coach",
                            },
                            {
                              value: "team_volunteer",
                              label: "Team volunteer",
                            },
                            { value: "fan", label: "Fan" },
                          ]}
                          value={filter ? filter.value : "all"}
                          handleChange={(val) => onChange(val)}
                        />
                      );
                    },
                  },
                  {
                    Header: "Delete",
                    headerClassName: "text-center",
                    className: "text-center button-wrapper",
                    sortable: false,
                    filterable: false,
                    Cell: (cellData) => (
                      <button
                        aria-label="Delete user"
                        className="delete-button user-delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUserDelete(cellData.original);
                        }}
                        type="button"
                        disabled={
                          currentUserId === Number(get(cellData, "original.id"))
                        }
                      >
                        <IconDelete
                          height="20px"
                          width="20px"
                          color="#ee5253"
                        />
                      </button>
                    ),
                    width: 100,
                  },
                ]}
                minRows={1}
                showPagination={false}
                defaultSorted={[
                  {
                    id: "email",
                    desc: false,
                  },
                ]}
              />
            </TabContent>
            <TabContent>
              {isAuth ? (
                <Table
                  data={notVerifiedUsers}
                  noDataText=""
                  filterable
                  NoDataComponent={() => null}
                  defaultFilterMethod={(filter, row) => {
                    const rowValue = row[filter.id] || "";
                    if (!rowValue) {
                      return false;
                    }
                    return String(row[filter.id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    );
                  }}
                  columns={[
                    {
                      accessor: "email",
                      Header: "Email",
                      Cell: (cellData) => displayValue(cellData.value),
                      Filter: (cellInfo) => {
                        const { filter, onChange } = cellInfo;

                        return (
                          <TextInput
                            placeholder="Search users by email"
                            handleChange={(e) => onChange(e.target.value)}
                            value={filter ? filter.value : ""}
                          />
                        );
                      },
                    },
                    {
                      accessor: "first_name",
                      Header: "First name",
                      Cell: (cellData) => displayValue(cellData.value),
                      Filter: (cellInfo) => {
                        const { filter, onChange } = cellInfo;

                        return (
                          <TextInput
                            placeholder="Search users by first name"
                            handleChange={(e) => onChange(e.target.value)}
                            value={filter ? filter.value : ""}
                          />
                        );
                      },
                    },
                    {
                      accessor: "last_name",
                      Header: "Last name",
                      Cell: (cellData) => displayValue(cellData.value),
                      Filter: (cellInfo) => {
                        const { filter, onChange } = cellInfo;

                        return (
                          <TextInput
                            placeholder="Search users by last name"
                            handleChange={(e) => onChange(e.target.value)}
                            value={filter ? filter.value : ""}
                          />
                        );
                      },
                    },
                    {
                      accessor: "userteam",
                      Header: "Role",
                      Cell: (cellData) => {
                        const id = get(cellData, "original.id");
                        const email = get(cellData, "original.email");
                        const userteam = get(cellData, "value") || [];
                        if (!get(userteam, "id")) {
                          return "-";
                        }

                        return (
                          <UserRole
                            currentUserId={userId}
                            fetchUsers={fetchUsers}
                            role={userteam}
                            teamId={teamId}
                            userRoleId={id}
                            userRoleEmail={email}
                            isAuth={isAuth}
                          />
                        );
                      },
                      // eslint-disable-next-line consistent-return
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        const rowValue = row[filter.id].role || "";
                        if (!rowValue) {
                          return false;
                        }

                        // if (filter.value === 'admin') {
                        //   return row[filter.id].role === 'admin' ||
                        //     row[filter.id].role === 'coach' ||
                        //     row[filter.id].role === 'scorekeeper';
                        // }
                        // if (filter.value === 'fan') {
                        //   return row[filter.id].role === 'fan';
                        // }
                        if (filter.value === rowValue) {
                          return true;
                        }
                        return false;
                      },
                      Filter: (cellInfo) => {
                        const { filter, onChange } = cellInfo;
                        return (
                          <Selector
                            options={[
                              { value: "all", label: "Show all" },
                              { value: "admin", label: "Admin" },
                              { value: "coach", label: "Coach" },
                              { value: "scorekeeper", label: "Scorekeeper" },
                              {
                                value: "assistant_coach",
                                label: "Assistant coach",
                              },
                              {
                                value: "team_volunteer",
                                label: "Team volunteer",
                              },
                              { value: "fan", label: "Fan" },
                            ]}
                            value={filter ? filter.value : "all"}
                            handleChange={(val) => onChange(val)}
                          />
                        );
                      },
                    },
                    {
                      accessor: "userplayers",
                      Header: "Family of",
                      Cell: (cellData) => {
                        const email = get(cellData, "original.email");
                        const players = get(cellData, "value") || [];
                        if (!players.length) {
                          return "-";
                        }

                        return (
                          <UserPlayer
                            fetchUsers={fetchUsers}
                            players={players}
                            isAuth={isAuth}
                            userRoleEmail={email}
                          />
                        );
                      },
                      // eslint-disable-next-line consistent-return
                      filterMethod: (filter, row) => {
                        const rowData = get(row, '["userplayers"]') || [];

                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < rowData.length; i++) {
                          const firstName =
                            get(rowData[i], "player.first_name") || "";
                          const lastName =
                            get(rowData[i], "player.last_name") || "";
                          if (
                            firstName
                              .toLowerCase()
                              .indexOf(filter.value.toLowerCase()) !== -1 ||
                            lastName
                              .toLowerCase()
                              .indexOf(filter.value.toLowerCase()) !== -1
                          ) {
                            return true;
                          }
                        }
                      },
                      Filter: (cellInfo) => {
                        const { filter, onChange } = cellInfo;
                        return (
                          <TextInput
                            placeholder="Search by players name"
                            handleChange={(e) => onChange(e.target.value)}
                            value={filter ? filter.value : ""}
                          />
                        );
                      },
                    },
                    {
                      Header: "Delete",
                      headerClassName: "text-center",
                      className: "text-center button-wrapper",
                      sortable: false,
                      filterable: false,
                      Cell: (cellData) => (
                        <button
                          aria-label="Delete user"
                          className="delete-button user-delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUserDelete(cellData.original);
                          }}
                          type="button"
                          disabled={
                            currentUserId ===
                            Number(get(cellData, "original.id"))
                          }
                        >
                          <IconDelete
                            height="20px"
                            width="20px"
                            color="#ee5253"
                          />
                        </button>
                      ),
                      width: 100,
                    },
                  ]}
                  minRows={1}
                  showPagination={false}
                  defaultSorted={[
                    {
                      id: "email",
                      desc: false,
                    },
                  ]}
                />
              ) : (
                <NotAuthorized message="Only admins can see pending invitations" />
              )}
            </TabContent>
            <TabContent>
              {isAuth ? (
                <UnderConstruction />
              ) : (
                <NotAuthorized message="Only admins can see requests" />
              )}
            </TabContent>
          </Tabs>
        </div>
      </div>
      {isAddUserDisplayed && (
        <AddUserForm
          isOpen={isAddUserDisplayed}
          close={() => setAddUserDisplay(false)}
          teamId={teamId}
          seasonId={seasonId}
          refreshTeam={fetchUsers}
          users={users}
        />
      )}
      {isMultipleAddUserDisplayed && (
        <AddMultipleUserForm
          isOpen={isMultipleAddUserDisplayed}
          close={() => setMultipleAddUserDisplay(false)}
          teamId={teamId}
          refreshTeam={fetchUsers}
        />
      )}
      {isDeleteUserDisplayed && (
        <DeleteUserPopup
          user={userToDelete}
          refreshUsersList={fetchUsers}
          isOpen={isDeleteUserDisplayed}
          close={() => {
            setDeleteUserDisplay(false);
            setUserToDelete(null);
          }}
        />
      )}
    </div>
  );
};

TeamCommunity.propTypes = {
  role: PropTypes.string,
  team: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
};

TeamCommunity.defaultProps = {
  role: null,
};

const mapStateToProps = (state) => ({
  currentUserId: get(state, "user.details.id"),
  seasonId: get(state, "activeTeam.activeSeason.id"),
});

export default connect(mapStateToProps, null)(TeamCommunity);
