/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import {
  // NavLink,
  Switch,
  Route,
} from "react-router-dom";
import { NotAuthorized, NotFound } from "shared/components";
import { isAdmin, isPlayersFamily } from "shared/helpers";
import {
  PlayerStatsBasic,
  PlayerStatsBatting,
  PlayerStatsPitching,
  // PlayerStatsFielding,
} from "./components";
import "./styles.scss";

const PlayerStats = ({ teamId, player }) => {
  const playerId = get(player, "player.id");
  const isAuth = isAdmin(teamId);

  if (!isAuth && !isPlayersFamily(playerId)) {
    return (
      <NotAuthorized message="Only team admins and players family can see players stats" />
    );
  }

  return (
    <div className="PlayerStats">
      {/* <div className="PlayerStats-menu">
        <NavLink exact to={`/${teamId}/players/${playerId}/stats`}>Batting</NavLink>
        <NavLink exact to={`/${teamId}/players/${playerId}/stats/pitching`}>Pitching</NavLink>
        <NavLink exact to={`/${teamId}/players/${playerId}/stats/fielding`}>Fielding</NavLink>
      </div> */}
      <Switch>
        <Route
          exact
          path="/:teamId/players/:playerId/stats"
          render={() => (
            <PlayerStatsBasic playerId={playerId} teamId={teamId} />
          )}
        />
        <Route
          exact
          path="/:teamId/players/:playerId/stats/batting"
          render={() => <PlayerStatsBatting teamId={teamId} />}
        />
        <Route
          exact
          path="/:teamId/players/:playerId/stats/pitching"
          render={() => <PlayerStatsPitching teamId={teamId} />}
        />
        {/* <Route
          exact
          path="/:teamId/players/:playerId/stats/fielding"
          render={() => <PlayerStatsFielding />}
        /> */}
        <Route render={() => <NotFound />} />
      </Switch>
    </div>
  );
};

PlayerStats.propTypes = {
  player: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default PlayerStats;
