/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { getActiveTeam } from "store/activeTeam/actions";
import { Button, DescriptionList, Notification } from "shared/components";
import {
  displayValue,
  getGenderLabel,
  getAgeLabel,
  getCountryLabel,
  isAdmin,
} from "shared/helpers";
import EditTeamForm from "./components/EditTeamForm";
import "./styles.scss";
import FallbackImage from "../../../../shared/components/icons/stockphoto.jpeg";
import EditLogoForm from "./components/EditLogoForm/EditLogoForm";
import { UploadLogo } from "./actions";
import { connect } from "react-redux";
const TeamHome = ({ team, getTeamAction }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImportConsentDisplayed, setImportConsentDisplay] = useState(false);
  const [preview, setPreview] = useState(null);

  const [isEditTeamModalDisplayed, setEditTeamModalDisplay] = useState(false);
  const [isLogoEditDisplayed, setIsLogoEditDisplayed] = useState(false);

  const image = useRef(null);

  const handleWrongFileType = () => {
    Notification("error", "Wrong file type", "Only .csv files can be uploaded");
    setUploading(false);
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  const handleSubmitClick = (val) => {
    console.log("what:", val);
    const formData = new FormData();
    formData.append("logo", val.file);
    UploadLogo(team.id, formData).then((res) => console.log(res));
    setSelectedFile(val.file);
    setIsLogoEditDisplayed(false);
    setImportConsentDisplay(true);
  };

  useEffect(() => {
    if (selectedFile) {
      console.log("img", selectedFile);
      image.current.src = URL.createObjectURL(selectedFile);
    }
  }, [selectedFile]);

  const isAuth = isAdmin(get(team, "id"));

  const handleLogoEditDisplayed = () => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can edit team"
      );
    }

    return setIsLogoEditDisplayed(true);
  };

  const handleTeamEditClick = () => {
    if (!isAuth) {
      return Notification(
        "error",
        "You are not authorized for this action",
        "Only admin can edit team"
      );
    }

    return setEditTeamModalDisplay(true);
  };
  console.log(team);

  return (
    <div className="TeamHome">
      <div className="TeamHome-actions">
        <Button size="sm" onClick={handleLogoEditDisplayed}>
          Edit team logo
        </Button>
      </div>
      <div className="Logo">
        <img
          ref={image}
          id="profile"
          src={team.logo ? team.logo : FallbackImage}
          alt="profile"
        />
      </div>
      <div className="TeamHome-actions">
        <Button size="sm" onClick={handleTeamEditClick}>
          Edit team
        </Button>
      </div>

      <div className="TeamHome-details">
        <DescriptionList
          data={[
            // { label: "League", value: displayValue(get(team, "league.name")) },
            // { label: "Division", value: displayValue(get(team, "division")) },
            {
              label: "Head coach",
              value: displayValue(get(team, "head_coach")),
            },
            {
              label: "Gender",
              value: displayValue(getGenderLabel(get(team, "gender"))),
            },
            {
              label: "Age group",
              value: displayValue(getAgeLabel(get(team, "age_group"))),
            },
            {
              label: "Innings per game",
              value: displayValue(get(team, "innings_per_game")),
            },
            {
              label: "Abbreviation",
              value: displayValue(get(team, "abbreviation")),
            },
            { label: "City", value: displayValue(get(team, "city")) },
            { label: "State", value: displayValue(get(team, "state")) },
            {
              label: "Country",
              value: displayValue(getCountryLabel(get(team, "country"))),
            },
            { label: "Zipcode", value: displayValue(get(team, "zipcode")) },
          ]}
        />
      </div>

      {isEditTeamModalDisplayed && (
        <EditTeamForm
          isOpen={isEditTeamModalDisplayed}
          close={() => setEditTeamModalDisplay(false)}
          team={team}
        />
      )}
      {isLogoEditDisplayed && (
        <EditLogoForm
          isOpen={isLogoEditDisplayed}
          close={() => setIsLogoEditDisplayed(false)}
          team={team}
          uploading={uploading}
          progress={progress}
          handleSubmitClick={handleSubmitClick}
          handleWrongFileType={handleWrongFileType}
          handleItemRemove={handleItemRemove}
          validFileTypes={".jpg, .png, .jpeg"}
          selectedFile={selectedFile}
          preview={preview}
          setPreview={setPreview}
        />
      )}
    </div>
  );
};

TeamHome.propTypes = {
  team: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  activeSeason: get(state, "activeTeam.activeSeason"),
  loading: get(state, "activeTeam.loading"),
  team: get(state, "activeTeam.details"),
  userTeams: get(state, "userTeams.details"),
});

const mapDispatchToProps = {
  getTeamAction: getActiveTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamHome);
